<template>
  <page-container3>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search">
        <search-render
          ref="config_render"
          :form="searchConfig"
          :model="searchConfig.model"
          :options="searchConfigOptions"
          :validateInfos="validateInfos"
          :resetFields="resetFields"
          @search="search"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">{{ t('余额表') }}</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('导出Excel')">
                  <export-excel-modal
                    :column-list="dynamicColumnItems1.list"
                    :datasource="dataSource"
                    :filename="t('余额表')"
                    :columns="columns2"
                    :pageSize="1000000"
                    :current="1"
                  />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('列设置')">
                  <setting-outlined @click="handleOpen" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('刷新')">
                  <reload-outlined @click="search" />
                </a-tooltip>
              </div>
<!--              <div class="ant-pro-table-list-toolbar-setting-item">-->
<!--                <a-tooltip title="导出Excel">-->
<!--                  <export-excel-modal-->
<!--                    :column-list="columns"-->
<!--                    :datasource="dataSource"-->
<!--                    :filename="'余额表'"-->
<!--                  />-->
<!--                </a-tooltip>-->
<!--              </div>-->
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? t('退出全屏') : t('全屏')">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <a-table
          class="steel_tapping2"
          bordered="true"
          :scroll="{ y: 470, x: 1000 }"
          :columns="columns"
          :loading="loading"
          :rowKey="'key'"
          :data-source="dataSource"
          :pagination="false"
        >
          <template #money="{ text }">
            {{ text ? $numeral.money(parseFloat(text)) : $numeral.money(0) }}
          </template>
          <template #SGCMWU="{ record }">
            {{ $numeral.money(mun(record)) }}
          </template>
        </a-table>
      </a-card>
    </div>
    <a-modal
      :title="t('列设置')"
      width="800px"
      :visible="visible"
      transitionName=""
      maskTransitionName=""
      :destroyOnClose="true"
      centered
      @cancel="handleCancel"
    >
      <a-tree
        checkable
        checkStrictly="true"
        :tree-data="treeData"
        :selectable="false"
        v-model:expandedKeys="expandedKeys"
        v-model:selectedKeys="selectedKeys"
        v-model:checkedKeys="checkedKeys"
        v-model:replaceFields="replaceFields"
      ></a-tree>
      <template #footer>
        <a-button key="submit" type="primary" @click="handleSubmit">{{ t('保存') }}</a-button>
        <a-button key="back" @click="handleCancel">{{ t('取消') }}</a-button>
      </template>
    </a-modal>
  </page-container3>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue';
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  RedoOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import getPageConfig from '@/components/page-model/index';
import { SearchConfig } from '@/components/page-model/typing';
import { analyse_bank_transaction_report } from '@/api/cerp-model';
import moment from 'moment';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { TreeDataItem } from 'ant-design-vue/lib/tree/Tree';
import ls from '@/utils/local-storage';
import { BALANCE_STATEMENT } from '@/store/mutation-type';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import { TableColumn } from '@/typing';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const loading: any = ref(false);
    const visible: any = ref(false);
    const treeData: TreeDataItem = ref([]);
    const expandedKeys = ref<string[]>([]);
    const selectedKeys = ref<string[]>([]);
    const checkedKeys = ref<any>({ checked: [], halfChecked: [] });
    const replaceFields = { children: 'children', title: 'name', key: 'key' };
    if(ls.get(BALANCE_STATEMENT)){
      checkedKeys.value.checked = JSON.parse(ls.get(BALANCE_STATEMENT));
    }
    watch(expandedKeys, () => {
      console.log('expandedKeys', expandedKeys);
    });
    watch(selectedKeys, () => {
      console.log('selectedKeys', selectedKeys);
    });
    watch(checkedKeys, () => {
      console.log('checkedKeys', checkedKeys);
      console.log('treeData', treeData);
    });
    const dataSource: any = ref([]);
    const dynamicColumnItems1: any = reactive({
      list: [],
    });
    const columns: any = ref([]);
    const columns2: any = ref([]);
    const columns1: any = [
      {
        title: t('币种'),
        dataIndex: 'currency',
        key: 'currency',
        width: '100px',
        fixed: 'left',
      },
      {
        title: t('总额'),
        dataIndex: 'total_amount',
        key: 'total_amount',
        width: '150px',
        slots: { customRender: 'money' },
      },
      {
        title: t('应付'),
        dataIndex: 'no_pay_amount',
        key: 'no_pay_amount',
        width: '150px',
        slots: { customRender: 'money' },
      },
      {
        title: t('垫付'),
        dataIndex: 'pay_later_amount',
        key: 'pay_later_amount',
        width: '150px',
        slots: { customRender: 'money' },
      },
      {
        title: t('可用余额'),
        dataIndex: 'available_balance',
        key: 'available_balance',
        width: '150px',
        slots: { customRender: 'money' },
      },
      {
        title: t('真实余额'),
        dataIndex: 'real_balance',
        key: 'real_balance',
        width: '150px',
        slots: { customRender: 'money' },
      },
    ];
    const searchConfig: SearchConfig = {
      settings: {
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          name: 'time_interval',
          label: '时间区间',
          label_i18n: '时间区间',
          disabled: false,
          allowClear: true,
          type: 'rangepicker',
          valueFormat: 'YYYY-MM-DD',
          showTime: false,
        },
      ],
      rules: {
        time_interval: [{ required: true, message: '请填时间区间' }],
      },
      model: reactive({
        org_id: current_org?.id,
        time_interval: ['2021-08-01',moment().format('YYYY-MM-DD')],
      }),
    };
    const searchConfigOptions = {};
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);
    const search = () => {
      loading.value = true;
      analyse_bank_transaction_report({
        current: 1,
        pageSize: 200,
        ...searchConfig.model,
      }).then((res: any) => {
        treeData.value.length = 0;
        columns.value = JSON.parse(JSON.stringify( columns1 ));
        columns2.value = JSON.parse(JSON.stringify( columns1 ));
        if(res && res.data_list && res.data_list.length > 0) {
          dataSource.value.length = 0;
          res.data_list.map((item: any) => {
            dataSource.value.push(item);
          })
        }
        if(res && res.account && res.account.length > 0) {
          let list: any = ["SGCMWU(EUR)", "SGCMWU1(EUR)", "SGCMWU2(EUR)", "SGCMWU3(EUR)", "SGCMWU4(EUR)", "SGCMWU5(EUR)", "SGCMWU6(EUR)", "SGCMWU7(EUR)", "SGCMWU8(EUR)", "SGCMWU9(EUR)", "SGCMWU(USD)", "SGCMWU1(USD)", "SGCMWU2(USD)", "SGCMWU(CAD)", "SGCMWU(HKD)", "SGCMWU(CNH)", "SGCMTW(EUR)", "SGCMTW(USD)", "SGCMTW(GBP)", "HKCGAW(USD/EUR/HKD)HK", "HKCGAW(EUR)GE"]
          list.reverse();
          list.map((item: any) => {
            if(!ls.get(BALANCE_STATEMENT)){
              checkedKeys.value.checked.push(item);
            }
            treeData.value.push({
              name: item,
              key: item,
            })
            columns2.value.splice(1, 0, {
              title: item,
              dataIndex: 'EUR_' + item,
              key: 'EUR_' + item,
              width: '150px',
              slots: { customRender: 'money' },
            });
          })
          treeData.value.map((item1: any) => {
            checkedKeys.value.checked.map((item: any) => {
              if(item1.name == item){
                columns.value.splice(1, 0, {
                  title: item,
                  dataIndex: 'EUR_' + item,
                  key: 'EUR_' + item,
                  width: '150px',
                  slots: { customRender: 'money' },
                });
              }
            })
          })
          treeData.value.reverse();

        }
        columns.value.splice(1, 0,
          {
            title: 'SGCMWU(EUR)总额',
            dataIndex: 'SGCMWU(EUR)',
            key: 'SGCMWU(EUR)',
            width: '160px',
            slots: { customRender: 'SGCMWU' },
        });
        columns2.value.splice(11, 0,
          {
            title: 'SGCMWU(EUR)总额',
            dataIndex: 'SGCMWU(EUR)',
            key: 'SGCMWU(EUR)',
            width: '160px',
            slots: { customRender: 'SGCMWU' },
        });
        const {
          state: columnState,
          dynamicColumns,
          dynamicColumnItems,
          handleColumnAllClick,
          handleColumnChange,
          reset,
          move,
        } = useTableDynamicColumns( columns2.value as TableColumn[],
          {
            checkAll: false,
            needRowIndex: false,
          },
        );

        dynamicColumnItems1.list = dynamicColumnItems.value;
        console.log('dynamicColumnItems1', dynamicColumnItems1)
        loading.value = false;
      })
    };
    search();
    const handleOpen = () => {
      visible.value = true;
    };
    const handleCancel = () => {
      visible.value = false;
    };
    const handleSubmit = () => {
      columns.value = JSON.parse(JSON.stringify( columns1 ));
      let arr: any = JSON.parse(JSON.stringify( treeData.value )).reverse();
      arr.map((item1: any) => {
        checkedKeys.value.checked.map((item: any) => {
          if(item1.name == item){
            columns.value.splice(1, 0, {
              title: item,
              dataIndex: 'EUR_' + item,
              key: 'EUR_' + item,
              width: '150px',
              slots: { customRender: 'money' },
            });
          }
        })
      })
      columns.value.splice(1, 0,
        {
          title: 'SGCMWU(EUR)总额',
          dataIndex: 'SGCMWU(EUR)',
          key: 'SGCMWU(EUR)',
          width: '160px',
          slots: { customRender: 'SGCMWU' },
        });
      ls.set(BALANCE_STATEMENT, JSON.stringify(checkedKeys.value.checked))
      visible.value = false;
    };
    const mun = (record: any) => {
      let sum: any = 0;
      if(record['EUR_SGCMWU1(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU1(EUR)']);
      }
      if(record['EUR_SGCMWU2(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU2(EUR)']);
      }
      if(record['EUR_SGCMWU3(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU3(EUR)']);
      }
      if(record['EUR_SGCMWU4(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU4(EUR)']);
      }
      if(record['EUR_SGCMWU5(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU5(EUR)']);
      }
      if(record['EUR_SGCMWU6(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU6(EUR)']);
      }
      if(record['EUR_SGCMWU7(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU7(EUR)']);
      }
      if(record['EUR_SGCMWU8(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU8(EUR)']);
      }
      if(record['EUR_SGCMWU9(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU9(EUR)']);
      }
      if(record['EUR_SGCMWU(EUR)']){
        sum = sum + parseFloat(record['EUR_SGCMWU(EUR)']);
      }
      return parseFloat(sum);
}
    return {
      current_org,
      t,
      mun,
      dynamicColumnItems1,
      searchConfig,
      columns2,
      searchConfigOptions,
      resetFields,
      validateInfos,
      dataSource,
      columns,
      loading,
      elRef,
      screenState,
      setFull,
      exitFull,
      visible,
      treeData,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      replaceFields,
      getPageConfig,
      handleOpen,
      handleCancel,
      handleSubmit,
      search,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ExclamationCircleOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    Draggable,
    DragContainer,
    FormModal,
    SearchRender,
  },
});
</script>
<style lang="less">
.steel_tapping2 {
  td {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}
</style>
